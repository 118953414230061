import { createRouter, createWebHistory } from "vue-router"
import helloWorld from "@/components/HelloWorld";
const routes = [
    {
        path:"/",
        name:'首页',
        component: helloWorld,
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes // 简写，相当于 routes: routes
});
export default router